import React from "react";
import PropTypes from 'prop-types';
import Img from "gatsby-image";
import styles from "./OrganisatieRowLogo.module.scss";

const OrganisatieRowLogo = ({ title, subtitle, text, image }) => {
  return (
    <div className={`${styles.ctalogo} container py-4`}>
      <div className="row">
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <div className={styles.innerPadding}>
            <h2 className="h4 text-primary mb-0">{subtitle}</h2>
            <h3 className="h2 text-primary">{title}</h3>
            <div className={styles.text} dangerouslySetInnerHTML={{__html: text}}></div>
          </div>
        </div>
        <div className="col-md-6 text-center">
          <Img 
            fluid={image.localFile.childImageSharp.fluid}
          />
        </div>
      </div>
    </div>
  )
}

export default OrganisatieRowLogo;

OrganisatieRowLogo.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string
}