import React from "react";
import { graphql } from "gatsby"

import SEO from "components/Global/SEO/";
import OrganisatieRowPhoto from "components/Organisaties/OrganisatieRowPhoto2";
import OrganisatieRowLogo from "components/Organisaties/OrganisatieRowLogo";

const Organisaties = ({data}) => {
  
  const organisaties = data.organisatiesPagina.data.organisaties;
  
  return (
    <>
      <SEO title="Organisaties" description="Organisaties" />
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-6">
            <h1 className="text-primary">{data.organisatiesPagina.data.titel.text}<span className="text-secondary">.</span></h1>
            <div dangerouslySetInnerHTML={{__html: data.organisatiesPagina.data.intro.html}}></div>
          </div>
        </div>
      </div>

      {organisaties.map((item, key) => {
        const organisatie = item.organisatie.document.data;
        if(organisatie.design === "Logo") {
          return (
            <OrganisatieRowLogo
              key={key} 
              title={organisatie.titel.text}
              subtitle={organisatie.subtitel.text}
              text={organisatie.tekst.html}
              image={organisatie.afbeelding}
            />
          )
        } else {
          return (
            <OrganisatieRowPhoto
              key={key} 
              title={organisatie.titel.text}
              subtitle={organisatie.subtitel.text}
              text={organisatie.tekst.html}
              image={organisatie.afbeelding}
            />
          )
        }
      })}
      
    </>
  );
}

export const query = graphql`
  query {
    organisatiesPagina: prismicOrganisatiepagina {
      id
      data {
        titel {
          text
        }
        intro {
          html
        }
        organisaties {
          organisatie {
            document {
              ... on PrismicOrganisatie {
                id
                data {
                  titel {
                    text
                  }
                  subtitel {
                    text
                  }
                  tekst {
                    html
                  }
                  design
                  afbeelding {
                    localFile {
                      url
                      childImageSharp {
                        fluid(maxWidth: 1000) {
                          ...GatsbyImageSharpFluid_noBase64
                          ...GatsbyImageSharpFluidLimitPresentationSize
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Organisaties;