import React from "react";
import PropTypes from 'prop-types';
import Image from 'components/Global/Image';
import styles from "./OrganisatieRowPhoto.module.scss";

const OrganisatieRowPhoto = ({ title, subtitle, text, image }) => {
  
  return (
    <div className={`${styles.ctafoto}`}>
      
      <div className={styles.boxWrap}>
        <div className={styles.backgroundOrange}>
          <div className={styles.inner}>
            <h2 className="text-white h4">{subtitle}</h2>
            <h3 className="text-white h2">{title}</h3>
            <div className={styles.text} dangerouslySetInnerHTML={{__html: text}}></div>
          </div>
        </div>
      </div>

      <div >
        <Image 
          src={image} 
          className={styles.image}
        />
      </div>

    </div>
  );
}

export default OrganisatieRowPhoto;

OrganisatieRowPhoto.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string
}